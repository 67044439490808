import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiAmazonaws,
  SiLaragon,
  SiLinux,
  SiXampp,
  SiArchlinux,

} from "react-icons/si";
import ReactTooltip from "react-tooltip";

function Toolstack() {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons" data-tip="macOS">
          <SiMacos />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="VS Code">
          <SiVisualstudiocode />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Slack">
          <SiSlack />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Laragon">
          <SiLaragon />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Linux">
          <SiLinux />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="XAMPP">
          <SiXampp />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Arch Linux (btw)">
          <SiArchlinux />
        </Col>
      </Row>
      <ReactTooltip />
    </>
  );
}

export default Toolstack;
