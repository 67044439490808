import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
  SiVuedotjs,
  SiLaravel,
  SiAmazonaws,
  SiMysql,
  SiSinglestore,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";
import ReactTooltip from "react-tooltip";

function Techstack() {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={2} className="tech-icons" data-tip="C++">
          <CgCPlusPlus />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Amazon AWS">
          <SiAmazonaws />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="JavaScript">
          <DiJavascript1 />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="React">
          <DiReact />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Vue.js">
          <SiVuedotjs />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Laravel">
          <SiLaravel />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Git">
          <DiGit />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Firebase">
          <SiFirebase />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="Python">
          <DiPython />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="MySQL">
          <SiMysql />
        </Col>
        <Col xs={4} md={2} className="tech-icons" data-tip="SingleStore">
          <SiSinglestore />
        </Col>
      </Row>
      <ReactTooltip />
    </>
  );
}

export default Techstack;