import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import zillow from "../../Assets/Projects/zillow.png";
import sudoku from "../../Assets/Projects/suduko.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Personal Projects
        </h1>
        <p style={{ color: "rgb(209,213,191)" }}>
        Here are some personal projects I have undertaken to enhance my learning and skills:

</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={sudoku}
              isBlog={false}
              title="Sudoku Solver"
              description="A Python application for solving Sudoku. The solver can solve Sudoku games ranging from easy to what is claimed to be the hardest Sudoku puzzle invented. The solver uses standard Sudoku logic to solve all but the hardest games. For the hardest games and having exhausted standard logic, the solver maintains a stack of exploratory moves to attempt to solve the game."
              ghLink="https://github.com/danielmurphy518/sudoku-solver"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={zillow}
              isBlog={false}
              title="Zillow Walker"
              description="A React Native mobile app that allows the user to instantly view the Zillow property value for any house they pass by on a walk or drive. The app runs on iPhone and Android and accesses the user's current location and orientation to provide property addresses and valuation details for properties as you walk by them. The app also allows the user to view properties within an adjustable radius of their location. The app utilizes Google mapping APIs and Zillow's property valuations APIs."
              ghLink="https://github.com/danielmurphy518/zillow-walker"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
