import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ABOUT ME
            </h1>
            <p className="home-about-body">
            I am a software engineer based in Wellington, New Zealand, with two years of experience specializing in fintech and data solutions.
              <br />
              <br />I have expertise in programming languages such as
              <i>
                <b className="purple"> Python, C++, Javascript </b>
              </i>
              <br />
              <br />
              I have worked with frameworks like   &nbsp;
              <i>
                <b className="purple">React, Vue, Laravel </b> and <b className="purple">React Native </b>
              </i>
              to develop web and mobile applications.
              <br />
              <br />
              Outside of work, I’m passionate about exploring and engaging with <b className="purple">Artificial Intelligence, Design, Linux</b> and <b className="purple">Mobile App Development.</b> Currently, I’m expanding my skills in mobile app development by learning <b className="purple">Swift</b> and <b className="purple">Kotlin</b>, and working with frameworks such as <b className="purple">SwiftUI</b> and <b className="purple">Jetpack Compose</b>.            </p>
          </Col>
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img className="img-fluid" alt="todo: put new image here" />
            </Tilt>
          </Col> */}
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
          <h1 style={{ color: 'rgb(209, 213, 191)' }}>FIND ME ON</h1>


            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
