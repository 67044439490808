import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "justify" }}>
  I am <span className="purple">Daniel Murphy</span>, a software engineer based in Wellington New Zealand
  <span className="purple"> Wellington, New Zealand.</span>
  <br />
  <br />
  I have completed a Bachelor of Science in Computer Science from the University of California, Irvine.
  <br />
  <br />
  My interests include cybersecurity, artificial intelligence and web design.
</p>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
